import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineDribbble} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'
import { FaLinkedin } from "react-icons/fa";

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/jack-kamire/', icon: <FaLinkedin />},
    { id: 1, link: 'https://github.com/Kamire-J', icon: <AiFillGithub /> },
    { id: 2, link: 'https://x.com/jac_curious', icon: <AiOutlineTwitter /> },
]

export default data