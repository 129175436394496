import {FaServer} from 'react-icons/fa'
import { FaDatabase } from "react-icons/fa";
import { BsFileBarGraph } from "react-icons/bs";
import { FaConnectdevelop } from "react-icons/fa";


const data = [
    {
        id: 1, icon: <FaServer/>, title: 'Backend Development',desc:
        "My goal is to ensure your application is built secure database foundation, with seamless integration server-side logic and business processes. Application security is priority, while delivering a seamless user experience."
    },
    {
        id: 2, icon: <BsFileBarGraph/>, title: 'Data Science', desc: "I leverage data analytics and machine learning to facilitate informed decision-making. Deriving meaningful patterns from complex data sets, maintaing data integrity, and building prediective models that are in line with your business ojbectives is my priority."
    },
    {
        id: 3, icon: <FaConnectdevelop/>, title: 'MLoPs', desc: "I use machine learning to optimize your process by transforming your data into actionable insights. I can build and deploy models that enhance business processes, from data ingestion to model deployment and monitoring. With expertise in integrating large language models(LLMs) into applications, I can deliver AI-driven systems that are efficient and ready to scale."
    },
    
    {
        id: 4, icon: <FaDatabase/>, title: 'Data Engineering', desc: "I design and develop scalable data infrastructure to guarantee a smooth data flow. My priorities are security, data integrity, and data privacy. My focus is to make sure you have the right solution to support your business growth."
    },
]


export default data